import React, { useState, useEffect } from 'react';
import { MessageCircle, Mail, Send, ArrowRight, Copy, Phone, Clock, Globe } from 'lucide-react';
import ReactCountryFlag from "react-country-flag";

import Container from '../../common/container';

// Previous animations remain the same...
const floatingAnimation = `
  @keyframes floating {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-8px); }
    100% { transform: translateY(0px); }
  }
`;

const pulseAnimation = `
  @keyframes pulse {
    0% { box-shadow: 0 0 0 0 rgba(var(--primary-rgb), 0.4); }
    70% { box-shadow: 0 0 0 10px rgba(var(--primary-rgb), 0); }
    100% { box-shadow: 0 0 0 0 rgba(var(--primary-rgb), 0); }
  }
`;

export default function ModernHero() {
  const [showCopySuccess, setShowCopySuccess] = useState(null);
  
  const contactOptions = [
    {
      icon: MessageCircle,
      title: "WhatsApp",
      href: "https://wa.me/254703867693/",
      color: "bg-primary",
      description: "Instant chat support available globally",
      type: "regular",
      isOnline: true,
      responseTime: "< 2 min",
      bestTime: "24/7",
      countryCode: "KE",
      isGlobal: true
    },
    {
      icon: Phone,
      title: "Call Us",
      phone: "+254703867693",
      color: "bg-primary",
      description: "Request Callback",
      callbackNote: {
        countryCode: "KE",
        text: "ONLY"
      },
      type: "phone",
      isOnline: true,
      responseTime: "Immediate",
      bestTime: "8AM - 8PM EAT",
      countryCode: "KE",
      isGlobal: true
    },
    {
      icon: Send,
      title: "Telegram",
      href: "https://t.me/get_wymbee",
      color: "bg-primary",
      description: "Quick responses available globally",
      type: "regular",
      isOnline: true,
      responseTime: "< 5 min",
      bestTime: "24/7",
      countryCode: "KE",
      isGlobal: true
    },
    {
      icon: Mail,
      title: "Email",
      email: "support@wymbee.com",
      color: "bg-primary",
      description: "Detailed assistance available globally",
      type: "email",
      isOnline: true,
      responseTime: "< 1 hour",
      bestTime: "24/7",
      countryCode: "KE",
      isGlobal: true
    }
  ];

  const [userLocation, setUserLocation] = useState({
    country: '',
    country_code: '',
    loading: true,
    error: null
  });
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        // First attempt with ipapi.co
        const response = await fetch('https://ipapi.co/json/');
        if (!response.ok) throw new Error('Location service unavailable');
        const data = await response.json();
        setUserLocation({
          country: data.country_name,
          country_code: data.country_code,
          loading: false,
          error: null
        });
      } catch (error) {
        console.error('ipapi.co failed, trying ipinfo.io:', error);
        try {
          // Fallback to ipinfo.io
          const response = await fetch('https://ipinfo.io/json');
          if (!response.ok) throw new Error('Location service unavailable');
          const data = await response.json();
          setUserLocation({
            country: data.country,
            country_code: data.country,
            loading: false,
            error: null
          });
        } catch (fallbackError) {
          console.error('Both services failed:', fallbackError);
          setUserLocation(prev => ({
            ...prev,
            loading: false,
            error: 'Unable to detect location'
          }));
        }
      }
    };
  
    fetchLocation();
  }, []);

  const handleCopy = (text, title) => {
    navigator.clipboard.writeText(text);
    setShowCopySuccess(title);
    setTimeout(() => setShowCopySuccess(null), 2000);
  };

  const renderCard = (option, index) => {
    const Icon = option.icon;
    const baseCardClasses = `
      relative bg-white/70 backdrop-blur-lg rounded-2xl p-8 h-full 
      border border-gray-100 shadow-lg transition-all duration-500
      hover:shadow-2xl hover:-translate-y-1 hover:bg-white/90
      animate-[floating_6s_ease-in-out_infinite]
    `;

    return (
      <div className={baseCardClasses} style={{ animationDelay: `${index * 0.2}s` }}>
        {/* Online Status Badge with Primary Flag */}
        <div className="absolute top-4 right-4 flex items-center gap-2">
          <div className="flex items-center gap-1.5">
            <div className={`w-2 h-2 rounded-full ${option.isOnline ? 'bg-green-500 animate-pulse' : 'bg-gray-300'}`} />
            <span className="text-xs font-medium text-gray-600">
              {option.isOnline ? 'Online' : 'Offline'}
            </span>
          </div>
          {!userLocation.loading && !userLocation.error && (
            <ReactCountryFlag 
              countryCode={userLocation.country_code}
              style={{
                fontSize: '0.3em',
              }}
            />
          )}
        </div>

        {/* Accent Border */}
        <div className={`absolute inset-x-0 -bottom-px h-1 ${option.color} rounded-b-2xl opacity-0 
                        transition-opacity duration-300 group-hover:opacity-100`} />
        
        {/* Icon with Pulse Effect */}
        <div className={`${option.color} w-14 h-14 rounded-2xl flex items-center justify-center 
                        transform -rotate-6 transition-transform duration-300 group-hover:rotate-0
                        animate-[pulse_2s_infinite]`}>
          <Icon className="w-7 h-7 text-white" />
        </div>

        {/* Content */}
        <div className="mt-6 space-y-4">
          <h3 className="text-xl font-semibold">{option.title}</h3>
          <div className="flex items-center gap-2">
            <p className="text-gray-600">{option.description}</p>
            {option.callbackNote && (
              <div className="inline-flex items-center gap-1.5 bg-gray-100 px-2 py-0.5 rounded-full">
                <ReactCountryFlag 
                  countryCode={option.callbackNote.countryCode}
                  style={{ fontSize: '1em' }}
                />
                <span className="text-xs font-semibold text-gray-600">{option.callbackNote.text}</span>
              </div>
            )}
          </div>

          {/* Response Time Indicator */}
          <div className="flex items-center gap-2 text-sm text-gray-500">
            <Clock className="w-4 h-4" />
            <span>Response time: {option.responseTime}</span>
          </div>

          {/* Best Time Indicator */}
          <div className="flex items-center gap-2 text-sm text-gray-500">
            <Globe className="w-4 h-4" />
            <span>{option.bestTime}</span>
          </div>

          {/* Service Region Indicator */}
          <div className="flex items-center gap-2 text-sm text-gray-500">
            {option.isGlobal ? (
              <div className="flex items-center">
                <Globe className="w-4 h-4 mr-1" />
                <span>Global Support Available</span>
              </div>
            ) : (
              <div className="flex items-center gap-1">
                <ReactCountryFlag
                  countryCode="KE"
                  style={{ fontSize: '1em' }}
                />
                <span>Kenya Only</span>
              </div>
            )}
          </div>
          
          {/* Contact-specific content */}
          {(option.type === 'email' || option.type === 'phone') && (
            <div className="mt-4 p-3 bg-gray-50 rounded-lg flex items-center justify-between relative">
              <span className="text-primary font-medium">
                {option.type === 'email' ? option.email : option.phone}
              </span>
              <button 
                className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                onClick={() => handleCopy(option.type === 'email' ? option.email : option.phone, option.title)}
              >
                <Copy className="w-4 h-4 text-gray-500" />
              </button>
              
              {/* Copy Success Animation */}
              {showCopySuccess === option.title && (
                <div className="absolute -top-8 right-0 bg-green-500 text-white text-sm py-1 px-3 rounded-full animate-fade-up">
                  Copied!
                </div>
              )}
            </div>
          )}

          {option.type === 'regular' && (
            <div className="flex items-center text-primary font-medium pt-2">
              Connect
              <ArrowRight className="w-4 h-4 ml-2 transform transition-transform duration-300 
                                   group-hover:translate-x-2" />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <section>
      <style>
        {floatingAnimation}
        {pulseAnimation}
      </style>
      <Container className="relative py-12 md:py-20">
        {/* Enhanced Background Elements */}
        <div className="relative">
          <div className="absolute w-96 h-96 bg-gradient-to-br from-primary/20 to-blue-500/20 rounded-full blur-3xl opacity-30 animate-pulse" />
          <div className="absolute w-96 h-96 bg-gradient-to-tr from-primary/20 to-purple-500/20 rounded-full blur-3xl opacity-30 animate-pulse" />
        </div>

        {/* Main Content */}
        <div className="relative py-20">
          {/* Header Section with Global Support Indication */}
          <div className="max-w-4xl mx-auto text-center mb-16 space-y-6">
            <div className="flex items-center justify-center gap-2 mb-4">
              <Globe className="w-8 h-8 text-primary" />
              <span className="text-xl text-gray-600">Global Support</span>
              {!userLocation.loading && !userLocation.error && (
                <div className="flex items-center gap-2">
                  <span className="text-gray-400">•</span>
                  <ReactCountryFlag 
                    countryCode={userLocation.country_code}
                    style={{
                      fontSize: '1.5em',
                    }}
                    className="shadow-sm rounded-sm"
                  />
                </div>
              )}
            </div>
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold">
              How Can We
              <span className="block text-primary mt-2">Help You Today?</span>
            </h1>
            <p className="text-gray-600 text-lg md:text-xl leading-relaxed">
              Our support team is here to assist you with any questions about our platform, 
              features, or how to get started with optimizing your inventory management.
            </p>
          </div>

          {/* Contact Options Grid */}
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
            {contactOptions.map((option, index) => (
              option.type === 'email' || option.type === 'phone' ? (
                <div key={option.title} className="group relative">
                  {renderCard(option, index)}
                </div>
              ) : (
                <a
                  key={option.title}
                  href={option.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="group relative"
                >
                  {renderCard(option, index)}
                </a>
              )
            ))}
          </div>

          {/* Stats Section */}
          <div className="mt-16 flex justify-center space-x-12 text-center">
            <div className="relative group">
              <div className="absolute inset-0 bg-primary/5 -m-4 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <p className="text-3xl font-bold text-primary">24/7</p>
              <p className="text-gray-600 mt-1">Support</p>
            </div>
            <div className="w-px bg-gray-200" />
            <div className="relative group">
              <div className="absolute inset-0 bg-primary/5 -m-4 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <p className="text-3xl font-bold text-primary">&lt; 5min</p>
              <p className="text-gray-600 mt-1">Response Time</p>
            </div>
            <div className="w-px bg-gray-200" />
            <div className="relative group">
              <div className="absolute inset-0 bg-primary/5 -m-4 rounded-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <p className="text-3xl font-bold text-primary">99.9%</p>
              <p className="text-gray-600 mt-1">Satisfaction</p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}